import { getLivanceApiAuthToken } from '../auth-api'

const HASURA_URL = process.env.HASURA_URL || ''
const HASURA_ADMIN_SECRET = process.env.HASURA_ADMIN_SECRET

interface ResponseHeader {
  authorization: string
}

const fetcherSsr = async (
  url: string,
  data: object | undefined = undefined,
  headers: object = {},
  method: string | undefined = undefined,
) => {
  const response = await fetch(url, {
    method: method || (data ? 'POST' : 'GET'),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(data),
  })

  const body = await response.json()

  return { status: response.status, body }
}

const fetcherLivanceApi = async (
  url: string,
  data: object | undefined = undefined,
  headers: object = {},
  method: string | undefined = undefined,
) => {
  const authHeader = await buildHeader()

  return await fetcherSsr(url, data, { ...authHeader, ...headers }, method)
}

const fetchGraphql = async (data: object, livanceApi = false): Promise<any> => {
  const headers = { 'x-hasura-admin-secret': HASURA_ADMIN_SECRET }

  if (livanceApi) {
    return fetcherLivanceApi(HASURA_URL, data, headers)
  }

  return fetcherSsr(HASURA_URL, data, headers)
}

let token: string
const buildHeader = async (): Promise<ResponseHeader> => {
  if (!token) {
    const authResponse = await getLivanceApiAuthToken()
    token = authResponse.body?.access_token
  }

  const headers = { authorization: `Bearer ${token}` }

  return headers
}

export { fetcherSsr, fetchGraphql }
