import { IAgendamento } from '@/interfaces/agendamento'
import { createContext, useState } from 'react'

export interface IAgendamentoContext {
  agendamento: IAgendamento | null
  handleSetAgendamento(agendamento: IAgendamento): void
}

interface AgendamentoProviderProps {
  children: React.ReactNode
}

export const AgendamentoContext = createContext<IAgendamentoContext | null>(null)

export const AgendamentoProvider: React.FC<AgendamentoProviderProps> = ({ children }) => {
  const [agendamento, setAgendamento] = useState<IAgendamento | null>(null)

  const handleSetAgendamento = (agendamento: IAgendamento) => {
    setAgendamento(agendamento)
  }

  return (
    <AgendamentoContext.Provider value={{ agendamento, handleSetAgendamento }}>
      {children}
    </AgendamentoContext.Provider>
  )
}
