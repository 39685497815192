import IUser from '@/interfaces/user'
import { fetcherSsr } from '@/lib/http-config/fetcher'
import { createContext, useContext, useState } from 'react'
import { AgendamentoContext, IAgendamentoContext } from './schedule'

export interface IUserContext {
  user: IUser | null
  checkIn(): Promise<void>
  updateUser(user: IUser): void
}

interface UserProviderProps {
  children: React.ReactNode
}

export const UserContext = createContext<IUserContext | null>(null)

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { agendamento } = useContext(AgendamentoContext) as IAgendamentoContext
  const [user, setUser] = useState<IUser | null>(null)

  const checkIn = async (): Promise<any> => {
    if (user) {
      const { base64Image } = user

      return await fetcherSsr('/api/checkin', {
        codAgendamento: agendamento?.codAgendamento,
        foto: base64Image,
      })
    }
  }

  const updateUser = ({ base64Image }: IUser): void => {
    setUser({ base64Image })
  }

  return (
    <UserContext.Provider value={{ user, checkIn, updateUser }}>
      {children}
    </UserContext.Provider>
  )
}
