import type { AppProps } from 'next/app'
import '../styles/globals.css'
import '../styles/input-otp.css'
// import { useEffect } from 'react'
import { appWithTranslation } from 'next-i18next'
// import Hotjar from '@hotjar/browser'
import { AgendamentoProvider } from 'providers/schedule'
import { ThemeProvider } from 'providers/theme'
import { UserProvider } from 'providers/user'

// const initHotjar = () => {
//   if (!Hotjar.isReady()) {
//     const HOTJAR_SITE_ID = process.env.NEXT_PUBLIC_HOTJAR_SITE_ID
//     const HOTJAR_VERSION = process.env.NEXT_PUBLIC_HOTJAR_VERSION
//     Hotjar.init(Number(HOTJAR_SITE_ID), Number(HOTJAR_VERSION))
//   }
// }

function MyApp({ Component, pageProps }: AppProps) {
  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'production') {
  //     initHotjar()
  //   }
  // }, [])

  return (
    <AgendamentoProvider>
      <UserProvider>
        <ThemeProvider>
          <Component {...pageProps} />
        </ThemeProvider>
      </UserProvider>
    </AgendamentoProvider>
  )
}

export default appWithTranslation(MyApp as any)
