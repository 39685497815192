import corpusTheme from '../../../packages/config-tailwind/tokens/projectColors.json'

export const parceiroIntegracaoConfig = {
  '0': corpusTheme['saludia'],
  '1': corpusTheme['porto-seguro'],
  '3': corpusTheme['care-plus'],
  '4': corpusTheme['omint'],
  '5': corpusTheme['kipp-saude'],
  '6': corpusTheme['alice'],
  '7': corpusTheme['sami'],
  '8': corpusTheme['abas'],
}
