import { parceiroIntegracaoConfig } from 'maps/mapParceiroIntegracaoConfig'
import React, { createContext, useState } from 'react'

const DEFAULT_SALUDIA_THEME = 0

export interface IThemeContext {
  theme: any

  handleSetTheme(theme: any): void
}

interface ThemeProviderProps {
  children: React.ReactNode
}

export const ThemeContext = createContext<IThemeContext>({
  theme: parceiroIntegracaoConfig[0],
} as IThemeContext)

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<any>(parceiroIntegracaoConfig[DEFAULT_SALUDIA_THEME])

  const handleSetTheme = (theme: any) => {
    setTheme(theme)
  }

  return (
    <ThemeContext.Provider value={{ theme, handleSetTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
