import { fetcherSsr } from './http-config/fetcher'

const LIVANCE_API_AUDIENCE = process.env.LIVANCE_API_AUDIENCE
const AUTH0_ISSUER_BASE_URL = process.env.AUTH0_ISSUER_BASE_URL
const INITIUM_M2M_CLIENT_ID = process.env.INITIUM_M2M_CLIENT_ID
const INITIUM_M2M_CLIENT_SECRET = process.env.INITIUM_M2M_CLIENT_SECRET

const getApiAuthToken = async (
  audience: string | undefined,
  issuer: string | undefined,
) => {
  const data = {
    client_id: INITIUM_M2M_CLIENT_ID,
    client_secret: INITIUM_M2M_CLIENT_SECRET,
    audience: audience || '',
    grant_type: 'client_credentials',
  }
  return fetcherSsr(`${issuer}/oauth/token`, data)
}

const getLivanceApiAuthToken = async (): Promise<any> => {
  return getApiAuthToken(LIVANCE_API_AUDIENCE, AUTH0_ISSUER_BASE_URL)
}

export { getLivanceApiAuthToken }
